import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { SubSink } from 'subsink';
import { ApolloError } from '@apollo/client/errors';
import { Router } from '@angular/router';
import {
  CreateProfileGQL,
  GetMeProfilesDocument,
  GetOrganizationsForProfileCreatePageGQL,
  GetOrganizationsForProfileCreatePageQuery,
} from '@designage/gql';
import {
  ToasterService,
  CurrentUserService,
  RoutingStateService,
  ProfileService,
} from '@desquare/services';
import { ExcludeMaybe } from '@desquare/types';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileFormComponent } from '../profile-form/profile-form.component';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ProfileFormComponent],
  selector: 'app-profile-create',
  template: `<app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>

    <div
      [class.hidden]="loading"
      class="container-fluid content-container-card create-profile-container"
    >
      <div class="row">
        <div class="col-12">
          <h4>{{ 'ADD_NEW_PROFILE' | translate }}</h4>
          <hr />
        </div>
      </div>

      <app-profile-form
        [formId]="'profileForm'"
        [activeOrganizations]="activeOrganizations"
        (valid)="isFormValid = $event"
        (pristine)="isFormPristine = $event"
        (profileFormSubmitted)="createProfile($event)"
      ></app-profile-form>

      <div class="row">
        <div class="col-12">
          <hr />

          <button
            form="profileForm"
            [disabled]="!isFormValid"
            type="submit"
            [class.btn-success]="!isFormPristine"
            [class.btn-outline-success]="isFormPristine"
            class="btn float-end"
          >
            {{ 'CREATE' | translate }}
          </button>

          <button
            (click)="navigateBack()"
            type="button"
            class="btn btn-outline-danger float-end"
          >
            {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div> `,
  styles: [
    `
      .create-profile-container {
        button.float-end {
          margin-left: 10px;
        }

        .selected-users-container {
          max-height: 400px;
          overflow: auto;
        }

        .default-selected-user {
          padding-left: 40px;
        }
      }
    `,
  ],
})
export class ProfileCreateComponent implements OnInit, OnDestroy {
  @Output() ownerIdChange = new EventEmitter<string>();

  private subs = new SubSink();

  isFormValid = false;
  isFormPristine = false;
  ownerId!: string;
  loaderMessage!: string;
  loading = false;
  activeOrganizations!: ExcludeMaybe<
    GetOrganizationsForProfileCreatePageQuery['organizations']
  >['results'];

  constructor(
    private profileService: ProfileService,
    private createProfileGQL: CreateProfileGQL,
    private toasterService: ToasterService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private routingStateService: RoutingStateService,
    private getActiveOrganizationsGQL: GetOrganizationsForProfileCreatePageGQL
  ) {}

  ngOnInit() {
    this.setProfileOwner();
    this.getActiveOrganizations();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setProfileOwner() {
    if (this.currentUserService.currentUser) {
      const { id, displayName, auth0Profile } =
        this.currentUserService.currentUser;
      if (id && displayName && auth0Profile && auth0Profile.email) {
        this.ownerId = id;
      }
    }
  }

  navigateBack() {
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
  }

  // TODO: fix form type later
  createProfile(form: any) {
    this.loading = true;
    this.loaderMessage = 'CREATING_PROFILE';

    this.subs.sink = this.createProfileGQL
      .mutate(
        {
          input: {
            name: form.name,
            locationInput: form.location,
            ownerId: form.ownerId,
            userIds: form.userIds,
            description: form.description,
            organizationUid: form.organizationUid,
            website: form.website,
            creatorRoleIds: this.currentUserService.roles,
          },
        },
        {
          refetchQueries: [
            {
              query: GetMeProfilesDocument,
            },
          ],
        }
      )
      .subscribe({
        next: ({ data }) => {
          if (
            data &&
            data.createProfile.isSuccessful &&
            data.createProfile.profile
          ) {
            this.profileService.profileListChanged.emit();
            this.toasterService.success('CREATE_PROFILE_SUCCESS');
            this.router.navigate(['/']);
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }

          this.loading = false;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('createProfile', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });

          this.loading = false;
        },
      });
  }

  getActiveOrganizations() {
    this.subs.sink = this.getActiveOrganizationsGQL
      .watch(
        {
          active: true,
        },
        {
          fetchPolicy: 'network-only',
        }
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          const { organizations } = data;
          if (organizations) {
            this.activeOrganizations = organizations.results;
          } else {
            this.activeOrganizations = [];
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getActiveOrganizations', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        },
      });
  }
}
